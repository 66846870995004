@import '../../common/styles/variables';

.dev-colorpicker {
    .dev-colorpicker-row-split {
        color: $orange-plain;

        hr {
            border-top: 1px solid $orange-division;
        }
    }
}
